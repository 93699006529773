



























































































































































































































































































































































































































































































































































































import {
  Component, Prop, Ref, Vue, Watch,
} from 'vue-property-decorator';
// @ts-ignore
import { CIQ } from 'chartiq/js/componentUI';
import tasqSignalsModule from '@/store/modules/tasqSignalsModule';
import { getComponent, sleep } from '@/utils/helpers';
import tasqProductionDataChartModule from '@/store/modules/tasqProductionDataChartModule';
import setpointModule from '@/store/modules/setpointModule';
import tasqsListModule from '@/store/modules/tasqsListModule';
import localForage from '@/lib/localForage';
import workflowModule from '@/store/modules/workflowModule';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/antd.css';
import assetsModule from '@/store/modules/assetsModule';

import TasqJob from '@/interfaces/tasqs/TasqJob';
import { getNameByEmail } from '@/utils/users';
import { Debounce } from 'vue-debounce-decorator';
import tasqFeedbackModule from '@/store/modules/tasqFeedbackModule';
import { getCustomConfig } from './resources';
// ChartIQ library resources
let globalStx = CIQ.ChartEngine;

@Component({
  components: {
    Dropdown: () => getComponent('common/Dropdown'),
    TasqLoader: () => getComponent('loaders/TasqLoader'),
    eventDetail: () => getComponent('tasqs/TasqEventSummaryPopup'),
    VueSlider,
  },
})
export default class AdvancedChartComponent extends Vue {
	@Prop() config: any

	@Prop({ type: Function, default: ({}) => {} }) chartInitialized!: Function

	@Ref('container') container!: HTMLElement

	selectedSignals: any = []

	avaialbleDesription: any = [];

  marker: any = [];

  monthRangeSelection: any = ''

  days: any = 30;

  loaderTag: any = null;

  showWellEventDetail = false;

  showAnnotation = false;

  showHistory = false;

  availableSignalData: any = [];

  drawingObjPayload: any = [];

  getCommentActions(comment) {
    let response = '';
    for (let x = 0; x < comment.ResponseData.Actions.length; x++) {
      let action_string = comment.ResponseData.Actions[x];
      const action_strings = action_string.split('<>');
	  // console.log(action_strings);

	  if (action_strings.length < 2) {
		 action_strings.push('');
		  // console.log(action_strings);
	  }

      //  console.log(action_strings);
      if (action_strings[1] == 'null') {
        action_string = action_strings[1];
      } else {
        action_string = `${action_strings[0]} ${action_strings[1]}`;
      }
      if (x > 0) {
        response = `${response}, ${action_string}`;
      } else {
        response += action_string;
      }
    }
    return response;
  }

  hideCloseColumn() {
  //  console.log('this')
    setTimeout(() => {
      const aTags = document.getElementsByTagName('th');
      const searchText = 'Close';
      let found;

      for (let i = 0; i < aTags.length; i++) {
        if (aTags[i].textContent == searchText) {
          found = aTags[i];
          break;
        }
        console.log(found);
      }
      if (found) {
        found.innerText = '';
      }
    }, 500);
  }

	stx: CIQ.ChartEngine | undefined | any

		dropdownLocation: any[] = []

    nodeid: any = null

    closeSeriesDialog() {
		  this.dropdownLocation = [];
    }

    firstLoad: any = true;

    hideDropdown() {
		  // console.log(this.selectedSignals);
      // @ts-ignore
      this.dropdownLocation = [];
      this.updateChartView();
      // this.stx.initialData = this.stx.chart.series[00]
    }

    async didSelectDropdownOption() {
		  // console.log(this.stx.drawingObjects);

		  // ts-ignore
    }

    didSelectDropdown(e) {
      // if (type == 'Status Changes' || type == 'Duplicate Tasq') {

      if (this.firstLoad) {
        	  this.selectedSignals = ['Gas Flowrate', 'Static Pressure', 'Casing Pressure', 'Tubing Pressure', 'Daily Gas', 'Daily Oil', 'Daily Water'].map((s) => ({ text: s, value: s, type: 'signal' }));
      }

      this.firstLoad = false;

		  const rect = e.srcElement.parentElement.getBoundingClientRect();
		  this.dropdownLocation = [rect.x - 80, rect.y];

		  // } else {
		  // 	const rect = e.srcElement.getBoundingClientRect()
		  // 	this.dropdownLocation = [rect.x - 13, rect.y + 60]
		  // }
    }

    // get avaialbleDataPointNames() {
    //   return this.availableSignalData.map((dataPoint) => ({ value: dataPoint.name, text: dataPoint.name, type: dataPoint.type }));
    // }

    mainSignalName = 'Gas Flowrate'

    addCustomSeries(series) {
		  // this.dataLoading = true;
      // console.log(this.selectedSignals)
      if (this.loaderTag) {
        this.loaderTag.show();
      }

      // this.stx.setLineStyle({ color: '#f3172d' });
      // this.stx.setGapLines('#f3172d');
      this.stx.setStyle('stx_yaxis', 'fontFamily', 'Arial');
      this.stx.setStyle('stx_yaxis', 'background', 'f3172d');
	  const colors = ['red', 'green', 'blue', 'orange', 'purple', 'yellow', 'pink', 'skin', 'brown'];
	  // const axis = new CIQ.ChartEngine.YAxis({ position: 'right' });
	  this.stx.chart.legend = {
	    x: 300,
	    y: 20,
	  };

      let mainChartDataSignalName = 'Gas Flowrate';

      let mainChartData = this.availableSignalData.find((dataPoint) => dataPoint.name === 'Gas Flowrate' && dataPoint.data && dataPoint.data.length > 2);

      if (!mainChartData) {
        mainChartData = this.availableSignalData.find((dataPoint) => dataPoint.name === 'Tubbing Pressure' && dataPoint.data && dataPoint.data.length > 2);
        mainChartDataSignalName = 'Tubing Pressure';
      }

      if (!mainChartData) {
        mainChartData = this.availableSignalData.find((dataPoint) => dataPoint.name === 'Static Pressure' && dataPoint.data && dataPoint.data.length > 2);
        mainChartDataSignalName = 'Static Pressure';
      }

      if (!mainChartData) {
        mainChartData = this.availableSignalData.find((dataPoint) => dataPoint.name === 'Casing Pressure' && dataPoint.data && dataPoint.data.length > 2);
        mainChartDataSignalName = 'Casing Pressure';
      }

      this.mainSignalName = mainChartDataSignalName;

      // console.log(mainChartData.data);

      if (mainChartData && mainChartData.name === mainChartDataSignalName) {
        this.stx.loadChart(mainChartData.name, {
          masterData: mainChartData.data,
          periodicity: {
            period: 1,
            interval: 3,
            timeUnit: 'minute',
          },
          stretchToFillScreen: true,

        });

        console.log(mainChartData);

        if (mainChartData.yxis === 'right2') {

          this.uiContextGlobal.stx.setYAxisPosition(new CIQ.ChartEngine.YAxis({ name: 'right2', position: 'right' }), 'right');
             this.uiContextGlobal.stx.chart.yAxis.position = 'right';
		        // console.log(axis);
		      } else if (mainChartData.yxis === 'left2') {
           this.uiContextGlobal.stx.setYAxisPosition(new CIQ.ChartEngine.YAxis({ name: 'left2', position: 'left' }), 'left');
             this.uiContextGlobal.stx.chart.yAxis.position = 'left';
		        // console.log(axis);
		      } else if (mainChartData.yxis === 'right1') {

		         this.uiContextGlobal.stx.setYAxisPosition(new CIQ.ChartEngine.YAxis({ name: 'right1', position: 'right' }), 'right');
               this.uiContextGlobal.stx.chart.yAxis.position = 'right';
		        // console.log(axis);
          console.log('gsafdsdaffsddf');
		      } else {
            this.uiContextGlobal.stx.chart.yAxis.position = 'left';
           this.uiContextGlobal.stx.setYAxisPosition(new CIQ.ChartEngine.YAxis({ name: 'left1', position: 'left' }), 'left');
            this.uiContextGlobal.stx.setLineStyle({ color: mainChartData.color || '#f3172d' });
        }

         this.uiContextGlobal.stx.setLineStyle({ color: mainChartData.color || colors[0] });
         this.uiContextGlobal.stx.setGapLines(mainChartData.color || colors[0]);
      }

      // this.stx.setYAxisPosition(new CIQ.ChartEngine.YAxis({ name: 'left1', position: 'left' }), 'left');

		  const visibleSeries = this.availableSignalData.filter((dataPoint) => series.includes(dataPoint.name));

      // console.log(visibleSeries);

		  if (visibleSeries) {
        visibleSeries.forEach((dataPoint, index) => {
          // const dataPoint = visibleSeries[index];

          let axis: any = null;
		      if (dataPoint.yxis === 'left1') {
		        axis = new CIQ.ChartEngine.YAxis({ name: 'left1', position: 'left' });
		        // console.log(axis);
		      } else if (dataPoint.yxis === 'right2') {
		        axis = new CIQ.ChartEngine.YAxis({ name: 'right2', position: 'right' });
		        // console.log(axis);
		      } else if (dataPoint.yxis === 'left2') {
		        axis = this.stx.chart.yAxis;
		        // console.log(axis);
		      } if (dataPoint.yxis === 'right1') {
		        axis = new CIQ.ChartEngine.YAxis({ name: 'right1', position: 'right' });
		        // console.log(axis);
		      }

          //  console.log(dataPoint.name)
          //    console.log(dataPoint)
          //           console.log(dataPoint.yxis)
          // console.log(axis)

          let updatedDataPoint = dataPoint.data;

          if (!dataPoint.name.includes('Daily')) {
            updatedDataPoint = dataPoint.data;
          }

          function disableZoom() {
            if (CIQ.touchDevice) {
              // fix the candlewidth (zoom level) at the desired size
              // @ts-ignore
              this.setCandleWidth(18);
            }
          }

          CIQ.ChartEngine.prototype.prepend('draw', disableZoom);

          // setTimeout(() => {
          if (dataPoint.name !== mainChartDataSignalName && updatedDataPoint &&  updatedDataPoint.length > 2) {
		      this.stx.addSeries(dataPoint.name, {
	        color: dataPoint.color || colors[index],
	        data: updatedDataPoint,
	        fillGaps: true,
		        ...(axis && { yAxis: axis }),
		        gapDisplayStyle: true,
	          renderer: 'Lines',
		        shareYAxis: true,
              type: 'lines',
              marginTop: 400, // give room for the legend
	      });
          }
        });
		  }

		  this.stx.draw();

		  this.dataLoading = false;
      // setTimeout(() => {
      if (this.loaderTag) {
        // this.loaderTag.hide()
      }
      // },3000)

      // console.log(this.stx.chart.series)
    }

    prepareSetpointData() {
		  if (setpointModule.adminSetpointData) {
		     this.availableSignalData = this.availableSignalData.concat(setpointModule.adminSetpointData);
		  }
    }

    showMonthRangeSlider = false;

    monthDragEnd(e) {
      console.log(this.monthRangeSelection);
      if (this.showMonthRangeSlider) {
        // console.error(e);
        const month = this.lastYearMonths.find((m) => m.text === this.monthRangeSelection);
        console.log(month);
        if (month) {
          this.showMonthRangeSlider = false;
          this.customDateUpdated(month);
        }
      }
    }

    prepareSignalProdData(loadProdData = true) {
      this.availableSignalData = [];
      // console.log('updated')
		  const tasqAvailableSignals = tasqSignalsModule.chartIQSignals;

	  if (tasqAvailableSignals) {
	    tasqAvailableSignals.forEach((currentSignal, i) => {
          let dataPoint: any = [];
		      if (currentSignal && currentSignal.dataset && currentSignal.dataset.length) {
            // console.log(currentSignal);
	     dataPoint = currentSignal.dataset.map((d, i) =>

            // console.log((new Date(currentSignal.time[i])).getDate())
              ({
	        Date: (new Date(currentSignal.time[i])),
		        DT: currentSignal.time[i],
	          Close: d,
              }));
          } else {
            dataPoint = [
              {
                Date: '2022-06-13T12:06:09.000Z',
                DT: '2022-06-13T12:06:09Z',
                Close: 0,
              },
            ];
          }

          // dataPoint = updatedDataPoint;
		      this.availableSignalData.push({
		        name: currentSignal.name,
		        data: dataPoint,
		        yxis: 'left',
		        type: 'signal',
		      });
	    });
	  }

      if (loadProdData) {
      	    const availableProdData: any = tasqProductionDataChartModule.adminChartProductionData;
		    const availableProdDataPoints = availableProdData.dataset.filter((dataset) => dataset.data && dataset.data.length);

        // console.log(availableProdData)

		    if (availableProdDataPoints) {
	    availableProdDataPoints.forEach((currentProdType, i) => {
	      const dataPoint = currentProdType.data.map((d, i) => ({

              DT: `${availableProdData.date[i]}T18:28:00Z`,
	        Close: Number.parseFloat(d),

		      }));

		      this.availableSignalData.push({
		        name: currentProdType.name,
		        data: dataPoint,
		        yxis: 'left',
		        type: 'production',
		      });
	    });
	  }
      }
    }

    get lastYearMonths() {
      const yearsList: any = [];
      const monthName = (['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']);
      const d = new Date();
      d.setDate(1);
      for (let i = 0; i <= 11; i++) {
        const text = (`${monthName[d.getMonth()]} ${d.getFullYear().toString().slice(-2)}`);
        const startDate = (`${d.getMonth() + 1}/${1}/${d.getFullYear()}`);
        const endDate = (`${d.getMonth() + 1}/${30}/${d.getFullYear()}`);

        // yearsList.push(`${monthName[d.getMonth()]} ${d.getFullYear().toString().slice(-2)}`);
        // console.log(d);

        // console.log(text);
        // console.log(d);
        // const endDate = d;
        const monthObject = { text, startDate, endDate };
        yearsList.push(monthObject);
        d.setMonth(d.getMonth() - 1);
      }

      // console.log();

      return yearsList.reverse();

      // return this.splitArrayIntoChunksOfLen(yearsList,3)
    }

    splitArrayIntoChunksOfLen(arr: any = [], len) {
      const chunks: any = []; let i = 0; const
        n = arr.length;
      while (i < n) {
        chunks.push(arr.slice(i, i += len));
      }
      return chunks;
    }

    selectedMonths: any = ''

    async customDateUpdated(data) {
      console.log(data);
      console.log(this.lastYearMonths);
      if (this.selectedMonths === (data.text)) {
        return;
      }

      const endDate: any = new Date(data.endDate);
      console.log(Date.now() - endDate);

      const daysBack = (Date.now() - endDate) < 0 ? 0 : Math.ceil(Math.abs(Date.now() - endDate) / (1000 * 60 * 60 * 24));

      console.log(endDate);
      console.log(daysBack);

      tasqSignalsModule.resetSignals();
      tasqProductionDataChartModule.resetProductionData();
      this.selectedMonths = (data.text);
      // this.availableSignalData = []

      this.loadedMonths.push(data.text);

      const promises: any[] = [];
      this.loaderTag.show();

      tasqsListModule.setFromNowBack(daysBack);
      console.log(daysBack);
      promises.push(tasqSignalsModule.getSignalsForTasqForChartIQ(daysBack));
      Promise.all(promises).then(async () => {
        // this.chartsLoading = false;
        this.dataLoading = false;

        this.loaderTag.show();

           	Object.keys(this.stx.chart.series).forEach((key) => {
          if (key && this.stx.chart.series[key]) {
            const series = this.stx.chart.series[key];

            this.stx.removeSeries(series, false);
          }
        });

        await tasqProductionDataChartModule.getProductionData(31);
      	 // @ts-ignore

        // console.log(this.stx);
        this.stx.masterData = [];
        this.stx.initialData = [];
        //

        // console.log(activeSeries);
      	 // eslint-disable-next-line no-restricted-syntax
        this.loaderTag.show();
        	  setTimeout(async () => {
        this.prepareData(true);

         }, 1500);

      	  setTimeout(async () => {
          this.loaderTag.hide();
          this.updateChartView(Math.ceil((daysBack + 240)));
          this.showMonthRangeSlider = true;
	  }, 5500);
      }, (err) => {
        // error occurred

      });
    }

     wellEventDetails : any = {
	  action: {},
	  createdBy: '',
	  createdDate: '',
       comment: '',
     }

     get events() {
       return this.getWellHistory.map((event) => {
         let actions = '';
         if (event.ActionsInputJSON && event.ActionsInputJSON.length) {
           actions = event.ActionsInputJSON;
         }
         return {
           x: new Date(event.time),
           id: event.id,
           type: 'circle',
           action: actions,
           jobType: event.JobType,
           comment: event.readableComment,
           createdBy: getNameByEmail(event.Username),
           createdDate: event.time,
           category: 'news',
           chartID: `marker-${event.id}`,
           headline: event.readableComment,
         };
       });
     }

  dataLoading: any = false;

  get candleWdith() {
    if (this.stx && this.stx.layout) {
      return this.stx.layout.candleWdith;
    }
    return 0;
  }

  get tasq() {
	  if (tasqsListModule.isBatchResponding) {
	    if (!tasqsListModule.activeTasq) {
	      tasqsListModule.setActiveTasq(tasqsListModule.checkedTasqs[0]);
	    }
	    return tasqsListModule.activeTasq as TasqJob;
	  }

	  if (assetsModule.activeTasq == undefined) {
	    return tasqsListModule.activeTasq as TasqJob;
	  }

	  return tasqsListModule.activeTasq as TasqJob;
  }

  	get wellHistoryList() {
	  return workflowModule.wellHistory;
  }

	getWellHistory: any = [];

	getReadableWellHistoryComment(comment) {
	  // @ts-ignore
	  var readableComment = 'Confirmed';
	  if (comment.ResponseData == null) {
	    // @ts-ignore
	    readableComment = '';
	    if (comment.Type != null && comment.Type == 'CYGNET') {
	      readableComment = 'Cygnet Comment';
	    }
	  } else if (comment.ResponseData.UserResponse != null) {
	    if (comment.ResponseData.UserResponse.Selection == 'YES') {
	      var readableComment = 'Confirmed';
	    } else if (comment.ResponseData.UserResponse.Selection == 'NO') {
	      var readableComment = 'No';
	    } else if (comment.ResponseData.UserResponse.Selection == 'NOT_SURE') {
	      var readableComment = 'Not sure of';
	    } else if (comment.ResponseData.UserResponse.Selection == 'AUTO') {
	      var readableComment = 'Closed automatically';
	    }

	    if (comment.ResponseData.UserResponse.Selection != 'AUTO' && (comment.JobType.toLowerCase() == 'anomaly' || comment.JobType.toLowerCase() == 'state change' || this.tasq.predictionType.toLowerCase() == 'anomaly' || this.tasq.predictionType.toLowerCase() == 'state change' || this.tasq.predictionType.toLowerCase() == 'prolonged anomaly')) {
	      readableComment += ' - Anomaly';
	    } else if (comment.JobType.toLowerCase() == 'off-target' || (this.tasq != undefined && this.tasq.predictionType.toLowerCase() == 'off-target')) {
	      readableComment += ' - Off-Target';
	    } else if (comment.JobType.toLowerCase() == 'off-target rt' || (this.tasq != undefined && this.tasq.predictionType.toLowerCase() == 'off-target rt')) {
	      readableComment += ' - Off-Target RT';
	    } else if (comment.JobType.toLowerCase() == 'setpoint') {
	      readableComment += ' - Setpoint';
	    }

	    if (comment.ResponseData.UserResponse.Selection != 'AUTO' && comment.ResponseData.UserResponse.SelectionDetails.Reason.length > 0) {
	      readableComment += ': ';
	      for (let x = 0; x < comment.ResponseData.UserResponse.SelectionDetails.Reason.length; x++) {
	        readableComment = `${readableComment} ${comment.ResponseData.UserResponse.SelectionDetails.Reason[0]}`;
	      }
	    }
	  } else {
	    if (comment.ResponseData.Validate == 'YES') {
	      var readableComment = 'Confirmed';
	    } else if (comment.ResponseData.Validate == 'NO') {
	      var readableComment = 'No';
	    } else if (comment.ResponseData.Validate == 'NOT_SURE') {
	      var readableComment = 'Not sure of';
	    } else if (comment.ResponseData.Validate == 'AUTO') {
	      var readableComment = 'Closed automatically';
	    }
	    if (comment.JobType.toLowerCase() == 'off-target' || (this.tasq != undefined && this.tasq.predictionType.toLowerCase() == 'off-target')) {
	      readableComment += ' - Off-Target';
	    } else if (comment.JobType.toLowerCase() == 'off-target rt' || (this.tasq != undefined && this.tasq.predictionType.toLowerCase() == 'off-target rt')) {
	      readableComment += ' - Off-Target RT';
	    } else if (comment.JobType.toLowerCase() == 'setpoint') {
	      readableComment += ' - Setpoint';
	    } else {
	      readableComment += ` - ${comment.JobType}`;
	    }
	  }

	  if (comment.JobType.toLowerCase() == 'off-target rt' || (this.tasq != undefined && this.tasq.predictionType.toLowerCase() == 'off-target rt')) {
	    if (comment.Payload != null && comment.Payload.Outcome != null) {
	      readableComment += ` - ${comment.Payload.Outcome}`;
	    }
	  }

	  readableComment = readableComment.replace('Off-Target RT', 'Off-Target Real Time');

	  return readableComment;
	}

	@Watch('wellHistoryList', { immediate: true })
	updateWellHistory() {
	  if (this.showMonthRangeSlider) {
	  const history: any = this.wellHistoryList;
	  this.getWellHistory = history.map((h, i) => {
	    console.log(h);
	    h.readableComment = this.getReadableWellHistoryComment(h);
	    h.id = `marker-${h.CommentID}`;
	    h.time = new Date(h.time);
	    h.type = 'circle';
		  h.category = 'news';
	    h.chartID = `${h.CommentID}`;
		  h.headline = this.getReadableWellHistoryComment(h);
	    h.details = h;
		  h.selectEvent = false;
	    return h;
	  });
	  } else {
	    return [];
	  }
	}

  loadedMonths: any = []


  uiContextGlobal: any = null;

  async mounted() {
	  const config = this.config || getCustomConfig();
    tasqSignalsModule.resetChartIQSignals();
	  tasqSignalsModule.resetSignals();
	  tasqProductionDataChartModule.resetProductionData();
	  // this.loaderTag = document.querySelector('cq-loader');
	  // this.loaderTag.show
	  tasqsListModule.setFromNowBack(0);
	  // this.dataLoading = true;
	  const { container } = this;
	  portalizeContextDialogs(container);

	  CIQ.ChartEngine.YAxis.prototype.maxDecimalPlaces = 0;

	    window.setTimeout(async () => {
	    const uiContext: any = this.createChartAndUI(config);
	    this.stx = uiContext.stx;
	    this.stx.drawingObjects = [];
		    globalStx = this.stx;

	    const axis = new CIQ.ChartEngine.YAxis({ position: 'left' });

	    this.stx.chart.legend = {
	      x: 300,
	      y: 20,
	    };

      this.uiContextGlobal = uiContext;

		    //   this.selectedSignals.push({ value: 'Flowrate', text: 'Flowrate' });
	    uiContext.stx.fitLeftToRight = false;
	      uiContext.stx.fitTight = true;
	      uiContext.stx.futureTick = false;
	      // uiContext.stx.chart.yAxis.position = 'left';
		    // uiContext.stx.setPeriodicity({ period: 1, interval: 1, timeUnit: 'minute' });
      new CIQ.Tooltip({
        stx: uiContext.stx, ohl: false, volume: false, series: true, studies: true,
      });
	    // @ts-ignore
		    // uiContext.stx.setSpan({
		    //   multiplier: 5,
		    //   base: 'day',
		    //   padding: 10,

		    // });
	    // @ts-ignore
		    uiContext.stx.setChartType('line');
	      uiContext.stx.yaxisLabelStyle = 'arrow';
	    uiContext.stx.layout.headsUp = {
	      dynamic: false,
	      floating: true,
	    };

      uiContext.stx.layout.rangeSlider = true;
      uiContext.stx.slider.updateStyles('stx_range_slider shading', 'borderTopColor', 'rgba(255, 0, 0)');
      // uiContext.stx.slider.updateStyles("stx_range_slider shading", "backgroundColor", "rgba(200, 50, 50, 0.45)");

		    this.stx.changeOccurred('vector');

      this.stx.chart.allowScrollFuture = false;
      this.stx.chart.allowScrollPast = false;

      // @ts-ignore
	      // this.stx.chart.xAxis.timeUnit = CIQ.SECOND;
	      // this.stx.chart.xAxis.timeUnitMultiplier = 5;
	    // @ts-ignore
	    this.chartInitialized({ chartEngine: uiContext.stx, uiContext, config });

	  this.loaderTag = document.querySelector('cq-loader');

	  this.dataLoading = false;
	      this.loaderTag.show();

      // console.log(this.lastYearMonths);

	    // console.log(this.stx);
	  }, 200);

    const promises: any[] = [];

    promises.push(tasqSignalsModule.getSignalsForTasqForChartIQ(0));
    Promise.all(promises).then(async () => {
      //  this.loaderTag.show();

      await tasqProductionDataChartModule.getProductionData(35);
      this.monthRangeSelection = this.lastYearMonths[this.lastYearMonths.length - 1].text;

      setTimeout(() => {
        this.prepareData(true);

      }, 3000);
      console.log('resolve');
    }, (err) => {
      // error occurred

    });
	  // Delay the call to createChartAndUI so any other AdvancedChart components on the page
	  // have a chance to call portalizeContextDialogs
    // tasqProductionDataChartModule.resetProductionData();

      	  setTimeout(async () => {

      this.showMonthRangeSlider = true;
	    if (tasqsListModule.activeTasq?.wellName) {
	    this.nodeid = tasqsListModule.activeTasq?.wellName;
	  }

		  const drawingObjPayload: any = await workflowModule.getChartDrawingDetails({ nodeID: tasqsListModule.activeTasq?.wellName });
	    this.drawingObjPayload = drawingObjPayload;
	    this.showAnnotations();
       this.updateChartView(2);

      // this.lastYearMonths.map(async (data) => {

      // })
	  }, 10500);
  }

  async showAnnotations() {
		  // console.log(this.stx.drawingObjects);
		  if (this.showAnnotation) {
	    this.showAnnotation = false;
	    const data = (this.stx.exportDrawings());
		  const drawing = data.map((d) => JSON.stringify(d));
	    // console.log(data);
	    this.stx.drawingObjects = [];
	    this.drawingObjPayload = data;
		  // console.log(drawing[0]);
		  // console.log(JSON.stringify(drawing[0]))
		  if (data.length) {
		    // ts-ignore
		    await workflowModule.postChartDrawingDetails({ nodeId: this.nodeid, payload: drawing });
		  }
		  } else {
		    const { drawingObjPayload } = this;
		    console.log(this.drawingObjPayload);
		    // add it to the chart
	    // @ts-ignore
		    this.stx.importDrawings(drawingObjPayload);
	    // @ts-ignore
		  this.stx.draw();
	    this.showAnnotation = true;
		  }
  }

  async beforeDestroy() {
	  // Destroy the ChartEngine instance when unloading the component.
	  // This will stop internal processes such as quotefeed polling.
		  // const drawing = this.stx.drawingObjects;
		  // ts-ignore

		  // ts-ignore
	  tasqsListModule.setFromNowBack(0);
		  const data = (this.stx.exportDrawings());
		  const drawing = data.map((d) => JSON.stringify(d));
		  // console.log(drawing[0]);
		  // console.log(JSON.stringify(drawing[0]))
		  if (data.length) {
		    // ts-ignore
		    await workflowModule.postChartDrawingDetails({ nodeId: this.nodeid, payload: drawing });
		  }
		  //  window['drawingObj'] = drawing
		  // await localForage.setItem('drawing', drawing);
		this.stx?.destroy();
  }

  createChartAndUI(config: any) {
	  // console.log(config);
	  const { container } = this;
	  const chart = new CIQ.UI.Chart();
	  // @ts-ignore
	  const uiContext: any = chart.createChartAndUI({ container, config, layout: { crosshair: true, interval: 'days', headsUp: true } });
	  return uiContext;
  }

  get drawingObjects() {
	  if (this.stx) {
	    return this.stx.exportDrawings();
	  }
	  return [];
  }

@Debounce(1000)
  prepareData(loadProdData = false) {
    this.stx.masterData = [];
    this.stx.initialData = [];
    this.availableSignalData = [];
		    this.prepareSignalProdData(loadProdData);
	  // console.log('im called');
		    // this.prepareSetpointData();
		    // console.log(this.availableSignalData.forEach((s) => console.log(s.name)));
		    this.availableSignalData = this.availableSignalData.map((signalData) => {
		      const signal: any = signalData;
		      if (signal.name === 'Oil Rate') {
		        // eslint-disable-next-line no-param-reassign
		        signal.name = 'Daily Oil';
		        signal.yxis = 'left1';
		        signal.color = '#03925e';
		      }
		      if (signal.name === 'Flowrate') {
		        signal.name = 'Gas Flowrate';
		        signal.yxis = 'left1';
		        signal.color = '#f3172d';
		      }

		      if (signal.name === 'Water Rate') {
		        signal.name = 'Daily Water';
		        signal.yxis = 'left1';
		        signal.color = 'blue';
		      }

		      if (signal.name === 'Oil Rate') {
		        signal.name = 'Daily Oil';
		      }

		      if (signal.name === 'Gas Rate') {
		        signal.name = 'Daily Gas';
		        signal.yxis = 'left2';
		        signal.color = '#C4A484';
		      }

		      if (signal.name === 'Gas Today') {
		        signal.name = 'Gas Volume Today';
		      }

		      if (signal.name === 'Oil Today') {
		        signal.name = 'Oil Volume Today';
		      }

		      // if (signal.name === 'Water Rate') {
		      //   signal.name = 'Water Volume Today';
		      // }
		      if (signal.name === 'GL GasRate') {
		        signal.name = 'Daily GL Gas';
		      }

		      if (signal.name == 'Casing Pressure') {
		        signal.color = '#FF00FF';
		        signal.yxis = 'right1';
		      }

		      if (signal.name === 'Tubing Pressure') {
		        signal.color = '#32CD32';
		        signal.yxis = 'right1';
		      }

		      if (signal.name === 'Static Pressure') {
		        signal.color = '#FFFF00';
		        signal.yxis = 'right1';
		      }

	    if (signal.name === 'pressure(line)') {
	      signal.name = 'Plunger_Line Pressure @ Open';
	    }

		      return signal;
		    });

    console.log(this.availableSignalData);
  }

  @Watch('drawingObjects')
async updateDrawingObjects(data) {
		  if (data && data.length) {
			  // @ts-ignore
		    const dataObj = (this.stx.exportDrawings());
		  const drawing = dataObj.map((d) => JSON.stringify(d));
		  // console.log(drawing[0]);
		  // console.log(JSON.stringify(drawing[0]))
		  if (dataObj.length) {
		    // ts-ignore
		    await workflowModule.postChartDrawingDetails({ nodeId: this.nodeid, payload: drawing });
		  }
		  }
}

  async updateChartView(multiplier = 1) {
    // let a: any = document.querySelector('cq-loader')
    // this.dataLoading = true;
    console.log(this.selectedSignals);

    const data = this.days;
    if (this.loaderTag) {
      this.loaderTag.show();
    }
    if (!this.selectedSignals.length) {
	    this.addCustomSeries(['Daily Gas', 'Daily Oil', 'Daily Water', 'Gas Flowrate', 'Static Pressure', 'Casing Pressure', 'Tubing Pressure']);
    } else {
      const activeSeries = this.selectedSignals.map((s) => s.value);
      // console.log(activeSeries);
    	  	Object.keys(this.stx.chart.series).forEach((key) => {
        const series = this.stx.chart.series[key];
		    this.stx.removeSeries(series, false);
      });
      // console.log(data);
		 this.addCustomSeries(activeSeries);
    }

    // setTimeout(async () => {
    //   console.log(data);
    //   if (data === 30) {
    //     // console.log('dkslfadkfk')
    //     // await this.stx.setPeriodicity({ period: 3, interval: 5, timeUnit: 'minute' });
    //     this.stx.setSpan({
    //       multiplier,
    //       base: 'day',

    //     });
    //     // console.log(this.candleWdith)
    //     this.stx.setCandleWidth(5);
    //     this.stx.draw();
    //   } else if (data === 2) {
    //     //  console.log(this.candleWdith)
    //     this.stx.setSpan({
    //       multiplier: 2,
    //       base: 'day',

    //     });
    //     this.stx.setCandleWidth(1);
    //   } else {
    //     //  console.log(this.candleWdith)
    //     await this.stx.setSpan(
    //       {
    //         multiplier: 4,
    //         base: 'day',

    //       },
    //     );
    //     this.stx.setCandleWidth(1);

    //     // await this.stx.setPeriodicity({ period: 1, interval: 3, timeUnit: 'minute' });
    //     // this.stx.setCandleWidth(2.09);
    //   }
    // }, 1500);
    this.dataLoading = false;
    if (this.loaderTag) {
      setTimeout(() => {
        this.loaderTag.hide();
        console.log(this.stx);
        this.stx.allowZoom = true;
        this.stx.allowScroll = true;
        tasqSignalsModule.resetChartIQSignals();
      }, 3000);
    }
  }

  @Watch('days')
  async updateDays(data) {
    await this.updateChartView();
  }

  async	showMarkers() {
		  // console.log(this.stx);
		  if (this.marker && this.marker.length) {
		    // const marker = new CIQ.Marker({});
		    // marker.remove()
      this.showHistory = false;
		    this.marker.forEach((m) => {
		      m.remove();
		    });
		    this.marker = [];
		  } else {
      const a = this.events;
      const self = this;
		  // Loop through the data and create markers
		  for (let i = 0; i < a.length; i++) {
		    const datum = a[i];
		    // datum.story = story;

        let yvalue = (this.stx.panels.chart.yAxis.high) - ((i + 1) * 20);
        if (yvalue < 50) {
          yvalue = (this.stx.panels.chart.yAxis.high);
        }
        // @ts-ignore
        const nodeMain: any = (new CIQ.Marker.Simple(datum));
        // nodeMain.node.style.top = '300px';

        // console.log(nodeMain)

		    const params = {

		      stx: this.stx,
          chartContainer: true,
          permanent: true,

		      xPositioner: 'date',
		      x: a[i].x,
          y: yvalue,
		      label: 'events',
		      node: nodeMain,
		    };
        // @ts-ignore
		    const marker: any = new CIQ.Marker(params);
		    console.log(marker);
		    // marker.node.classList.toggle('highlight');
        // marker.node.classList.toggle('top-20');
        // marker.node.style.top = '300px';
        marker.node.style.zIndex = '50';
        marker.node.id = datum.id;
        marker.node.onclick = function (event, datum) { // asign a function
          console.log(event.target.parentNode.id);
          console.log(event.target);
          // event.target.style.display = 'none'
          // code
          console.log(self);
          self.showWellEventDetail = true;
          self.wellEventDetails = a.find((h) => h.id === event.target.parentNode.id);
          console.log(this);
        };
		      this.marker.push(marker);
		  }
      // @ts-ignore
		  this.stx.draw();
      this.showHistory = true;
		  }
  }
}

/**
 * For applications that have more then one chart, keep single dialog of the same type
 * and move it outside context node to be shared by all chart components
 */
function portalizeContextDialogs(container: HTMLElement) {
  container.querySelectorAll('cq-dialog').forEach((dialog) => {
    dialog.remove();
    if (!dialogPortalized(dialog)) {
      document.body.appendChild(dialog);
    }
  });
}
function dialogPortalized(el: Element) {
  if (!el.firstChild) {
    throw new Error('Element has no children');
  }
  const tag = el.firstChild.nodeName.toLowerCase();
  const result = Array.from(document.querySelectorAll(tag)).some(
    (el) => !el.closest('cq-context'),
  );
  return result;
}
